
/* A wrapper around a loading indicator when presented standalone */
.loading {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 8rem;
}
    
.title {
  margin-left: 0.5rem;
}
