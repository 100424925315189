@import "../../common/colors";

.box {
  border: 4px solid use-color("primary");
  border-radius: 1rem;
  margin: 1rem;
  padding: 1rem;
}

.prop-table {
  display: flex;
  flex-direction: column;
}

.prop-table > div {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
}

.prop-table > div > div:nth-child(1) {
  flex: 0 0 12rem;
  font-weight: bold;
}

.prop-table > div > div:nth-child(2) {
  flex: 1 1 0;
}

.paper {
  background-color: use-color("base-lightest");
  height: 100%;
}

.section-title {
  font-weight: bold;
}
