.main {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.icon {
  height: 24px;
  margin-right: 0.25em;
}
